import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import _ from 'lodash';
import { isBrowser } from '../../utils/isBrowser';
import Recaptcha from 'react-google-recaptcha';

import TextField from './TextField';
import TextArea from './TextArea';
import RichText from './RichText';
import Checkbox from './Checkbox';
import Button from './Button';
import { useInputState } from '../../hooks/useInputState';
import { cn } from '../../helper';
import { mapRequestData, contactMapping } from '../../utils/form';

const FormContact = ({
	name,
	className,
	terms,
	redirectUri,
	submitLabel,
	eventInformation,
}) => {
	const recaptchaSiteKey = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY;
	//const recaptchaSiteKey = "6LfW2jAbAAAAALVxxhlv6WpM2F6IEPcDBFiWKRFZ";
	const recaptchaEnabled = isBrowser && _.isString(recaptchaSiteKey) && recaptchaSiteKey !== '';

	const { t, i18n } = useTranslation();
	const formName = name + '-';
	const finalSubmitLabel = submitLabel || t('Form.submit');

	const [isPending, setPending] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [pendingMessage, setPendingMessage] = useState(null);
	const [recaptchaReady, setRecaptchaReady] = useState(false);

	const { language } = i18n;
	const [givenName, setGivenName] = useInputState('');
	const [familyName, setFamilyName] = useInputState('');
	const [organization, setOrganization] = useInputState('');
	const [email, setEmail] = useInputState('');
	const [phone, setPhone] = useInputState('');
	const [subject, setSubject] = useInputState('');
	const [message, setMessage] = useInputState('');
	const recaptchaRef = useRef(null);

	if (isBrowser) {
		const waitForCaptchaInitialization = () => {
			return new Promise((resolve, reject) => {
				const intervalId = setInterval(() => {
					if (!!window.grecaptcha && !!window.grecaptcha.render) {
						clearInterval(intervalId);
						resolve(window.grecaptcha);
					}
				}, 100);
			});
		}

		waitForCaptchaInitialization().then((grecaptcha) => {
			setRecaptchaReady(!!grecaptcha);
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (isPending) return;

		setErrorMessage(null);
		setPendingMessage(null);

		const recaptcha = recaptchaRef.current?.getValue();

		if (recaptchaEnabled && !recaptcha) {
			setErrorMessage(t('Form.error.recaptchaPending'));
			return;
		}

		const data = {
			language,
			givenName,
			familyName,
			organization,
			email,
			phone,
			subject,
			message,
			recaptcha,
			eventInformation,
		};

		sendData(data);
	};

	const sendData = async (data = {}) => {
		setPending(true);
		setPendingMessage(t(`Form.pendingMessage.contact`));
		try {
			const requestData = mapRequestData({ mapping: contactMapping, data });
			await Axios.post('/api/relution-contact-form', requestData);
			navigate(redirectUri);
		} catch (error) {
			setErrorMessage(t('Form.error.registrationUnkown'));
			console.error('## Error submitting form:', error);
			console.error('## Data:', data);
		} finally {
			setPending(false);
		}
	};

	const recaptchaOnExpired = (value) => {
		console.log("recaptchaOnExpired", value);
		setErrorMessage(t('Form.error.recaptchaExpired'));
		console.warn('## reCAPTCHA expired.');
	};

	const recaptchaOnErrored = (value) => {
		console.log("recaptchaOnErrored", value);
		setErrorMessage(t('Form.error.recaptchaNotLoaded'));
		console.error('## reCAPTCHA encountered an error.');
	};

	const recaptchaAsyncScriptOnLoad = (value) => {
		console.log("recaptchaAsyncScriptOnLoad", value);
		console.log("## window.grecaptcha", window.grecaptcha);

		if (isBrowser) {
			if (typeof window.grecaptcha !== 'undefined') {
				console.log('## reCAPTCHA script loaded successfully.');
				setErrorMessage(null);
			} else {
				console.error('## reCAPTCHA script did not initialize grecaptcha.');
				setErrorMessage(t('Form.error.recaptchaNotLoadedAsync'));
			}
		}
	};

	return (
		<form autoComplete="on" className={className} onSubmit={handleSubmit}>
			<div className="grid gap-x-5 gap-y-4 sm:grid-cols-2">
				<TextField
					label={t('Form.label.givenName')}
					htmlId={formName + 'givenName'}
					name="givenName"
					value={givenName}
					onChange={setGivenName}
					autoComplete="given-name"
					required
				/>
				<TextField
					label={t('Form.label.familyName')}
					htmlId={formName + 'familyName'}
					name="familyName"
					value={familyName}
					onChange={setFamilyName}
					autoComplete="family-name"
					required
				/>
				<TextField
					label={t('Form.label.organization')}
					htmlId={formName + 'organization'}
					name="organization"
					value={organization}
					onChange={setOrganization}
					autoComplete="organization"
					required
				/>
				<TextField
					label={t('Form.label.email')}
					htmlId={formName + 'email'}
					name="email"
					value={email}
					onChange={setEmail}
					autoComplete="email"
					required
					type="email"
				/>
				<TextField
					label={t('Form.label.phone')}
					htmlId={formName + 'phone'}
					name="phone"
					value={phone}
					onChange={setPhone}
					autoComplete="tel"
					required
					type="tel"
				/>
				<TextField
					label={t('Form.label.subject')}
					htmlId={formName + 'subject'}
					name="subject"
					value={subject}
					onChange={setSubject}
					type="text"
					className="sm:col-span-2"
				/>
				<TextArea
					label={t('Form.label.message')}
					htmlId={formName + 'message'}
					name="message"
					value={message}
					onChange={setMessage}
					className="sm:col-span-2"
				/>
				<div className="pt-10 sm:col-span-2">
					<Checkbox
						name="privacypolicy"
						htmlId={formName + 'privacypolicy'}
						required
						label={terms}
					/>
				</div>
			
				{recaptchaEnabled && (
					<div className="pt-6">
						{recaptchaReady ? (
							<Recaptcha
								ref={recaptchaRef}
								sitekey={recaptchaSiteKey}
								size="compact"
								asyncScriptOnLoad={recaptchaAsyncScriptOnLoad}
								onErrored={recaptchaOnErrored}
								onExpired={recaptchaOnExpired}
							/>
						) : (
							<p>{t('Form.error.loadingRecaptcha')}</p>
						)}
					</div>
				)}
				{errorMessage && (
					<RichText className="text-error sm:col-span-2">{errorMessage}</RichText>
				)}
				<div className="pt-6 sm:col-span-2">
					<Button
						variant="primary"
						as="button"
						type="submit"
						className={cn(isPending && 'opacity-50')}
						showLinkIcon
					>
						{finalSubmitLabel}
					</Button>
				</div>
				{pendingMessage && isPending && (
					<RichText className="sm:col-span-2">{pendingMessage}</RichText>
				)}
			</div>
		</form>
	);
};

FormContact.defaultProps = {
	name: 'contact',
};

FormContact.propTypes = {
	name: PropTypes.string,
	redirectUri: PropTypes.string,
	terms: PropTypes.node,
	submitLabel: PropTypes.string,
	eventInformation: PropTypes.string,
	className: PropTypes.string,
};

export default FormContact;