import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Axios from 'axios'
import _ from 'lodash'
import Recaptcha from 'react-google-recaptcha'
import { isBrowser } from '../../utils/isBrowser'

import TextField from './TextField'
import TextArea from './TextArea'
import Select from './Select'
import RichText from './RichText'
import Checkbox from './Checkbox'
import Button from './Button'
import { Products } from '../../constants/enums'
import { useInputState } from '../../hooks/useInputState'
import { cn } from '../../helper'
import {
	mapRequestData,
	cloudRegistrationMapping,
	onPremiseRegistrationMapping,
	contactMapping,
} from '../../utils/form'

const AxiosOptions = {
	[Products.free]: {
		[Products.cloud]: {
			apiUrl: '/api/relution-cloud-registration',
			dataMapping: cloudRegistrationMapping,
		},
		[Products.onpremise]: {
			apiUrl: '/api/relution-on-premise-registration',
			dataMapping: onPremiseRegistrationMapping,
		},
	},
	[Products.cloud]: {
		apiUrl: '/api/relution-contact-form',
		dataMapping: contactMapping,
	},
	[Products.onpremise]: {
		apiUrl: '/api/relution-contact-form',
		dataMapping: contactMapping,
	},
}

const FormRegistration = ({ name, variant, className, terms, redirects }) => {
	const recaptchaSiteKey = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY
	//const recaptchaSiteKey = "6LfW2jAbAAAAALVxxhlv6WpM2F6IEPcDBFiWKRFZ";
	const recaptchaEnabled =
		isBrowser && _.isString(recaptchaSiteKey) && recaptchaSiteKey !== ''
	const { t, i18n } = useTranslation()
	const formName = name + '-'
	const showProductOptions = variant === 'free'
	const productOptions = [
		{ text: t('Products.cloud'), value: Products.cloud },
		{ text: t('Products.onpremise'), value: Products.onpremise },
	]
	const howhearOptions = [
		{ text: t('Form.howhearOption.googleSearch'), value: 'googleSearch' },
		{ text: t('Form.howhearOption.recommendation'), value: 'recommendation' },
		{ text: t('Form.howhearOption.socialMedia'), value: 'socialMedia' },
		{ text: t('Form.howhearOption.otherWebsite'), value: 'otherWebsite' },
		{ text: t('Form.howhearOption.tradeFair'), value: 'tradeFair' },
	]

	const organizationTypeOptions = [
		{ text: t('Form.organizationTypeOption.education'), value: 'education' },
		{
			text: t('Form.organizationTypeOption.organization'),
			value: 'organization',
		},
		{ text: t('Form.organizationTypeOption.public'), value: 'public' },
		{ text: t('Form.organizationTypeOption.healthcare'), value: 'healthcare' },
		{ text: t('Form.organizationTypeOption.ngo'), value: 'ngo' },
		{ text: t('Form.organizationTypeOption.other'), value: 'other' },
	]

	const [isPending, setPending] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [pendingMessage, setPendingMessage] = useState(null)
	const [recaptchaReady, setRecaptchaReady] = useState(false);

	const { language } = i18n
	const [givenName, setGivenName] = useInputState('')
	const [familyName, setFamilyName] = useInputState('')
	const [organization, setOrganization] = useInputState('')
	const [organizationType, setOrganizationType] = useState('')
	const [email, setEmail] = useInputState('')
	const [phone, setPhone] = useInputState('')
	const [howhear, setHowhear] = useState('')
	const [product, setProduct] = useState('')
	const [message, setMessage] = useInputState('')
	const recaptchaRef = useRef(null)

	if (isBrowser) {
		const waitForCaptchaInitialization = () => {
			return new Promise((resolve, reject) => {
				const intervalId = setInterval(() => {
					if (!!window.grecaptcha && !!window.grecaptcha.render) {
						clearInterval(intervalId);
						resolve(window.grecaptcha);
					}
				}, 100);
			});
		}

		waitForCaptchaInitialization().then((grecaptcha) => {
			setRecaptchaReady(!!grecaptcha);
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		if (isPending) return
		setErrorMessage(null)
		setPendingMessage(null)

		const recaptcha = recaptchaRef.current?.getValue()

		if (recaptchaEnabled && !recaptcha) {
			setErrorMessage(t('Form.error.recaptchaPending'))
			return
		}

		let axiosOptions = AxiosOptions[variant]

		if (variant === Products.free) {
			axiosOptions = axiosOptions[product]
		}

		if (axiosOptions) {
			const options = axiosOptions
			const data = {
				language,
				givenName,
				familyName,
				organization,
				organizationType,
				email,
				phone,
				howhear,
				product,
				message,
				recaptcha,
				subject: 'Inquiry offering Cloud/On Premises (' + variant + ')',
			}
			sendData(options, data)
		}
	}

	function recaptchaOnExpired(value) {
		setErrorMessage(t('Form.error.recaptchaExpired'))
	}

	function recaptchaOnErrored(value) {
		setErrorMessage(t('Form.error.recaptchaNotLoaded'))
	}

	function recaptchaAsyncScriptOnLoad(value) {
		if (!value.loaded && value.loaded != undefined) {
			setErrorMessage(t('Form.error.recaptchaNotLoaded'))

		} else if (!value.errored && value.errored != undefined) {
			setErrorMessage(t('Form.error.recaptchaNotLoaded') + '(Errored:' + value.errored + ')');

		} else {
			//Script has been loaded. Hide the error message
			setErrorMessage(null)
		}

	}

	const sendData = async (options, data = {}) => {
		setPending(true)
		const pendingMessageId =
			variant === Products.free ? `${variant}_${product}` : variant
		setPendingMessage(t(`Form.pendingMessage.${pendingMessageId}`))

		const redirectUri =
			variant === Products.free ? redirects[product] : redirects.other

		try {
			const requestData = mapRequestData({ mapping: options.dataMapping, data })
			const result = await Axios.post(options.apiUrl, requestData)

			if (variant === Products.free && product === Products.cloud) {
				const response = result.data

				// Legacy code taken over from old site:
				if (response.JSESSIONID) {
					let myDate = new Date()

					myDate.setMinutes(myDate.getMinutes() + 10)
					document.cookie =
						'JSESSIONID=' +
						response.JSESSIONID +
						';expires=' +
						myDate.toUTCString() +
						';path=/;domain=.relution.io'
					window.open('https://live.relution.io', '_blank')
					setTimeout(() => {
						window.location.href = redirectUri
					})
				} else if (
					response.errorMessage &&
					response.errorMessage.includes(
						'"errorCode":"REGISTRATION_EMAIL_ALREADY_IN_USE"',
					)
				) {
					setErrorMessage(t('Form.error.registrationEmailInUse'))
				} else {
					setErrorMessage(t('Form.error.registrationUnkown'))
				}
			} else {
				navigate(redirectUri)
			}
		} catch (error) {
			setErrorMessage(t('Form.error.registrationUnkown'))
			console.warn(error)
		} finally {
			setPending(false)
		}
	}

	return (
		<form autoComplete="on" className={className} onSubmit={handleSubmit}>
			<div className="grid gap-x-5 gap-y-4 sm:grid-cols-2">
				<TextField
					label={t('Form.label.givenName')}
					htmlId={formName + 'givenName'}
					name="givenName"
					value={givenName}
					onChange={setGivenName}
					autoComplete="given-name"
					required
				/>
				<TextField
					label={t('Form.label.familyName')}
					htmlId={formName + 'familyName'}
					name="familyName"
					value={familyName}
					onChange={setFamilyName}
					autoComplete="family-name"
					required
				/>
				<TextField
					label={t('Form.label.organization')}
					htmlId={formName + 'organization'}
					name="organization"
					value={organization}
					onChange={setOrganization}
					autoComplete="organization"
					required
				/>
				<Select
					label={t('Form.label.organizationType')}
					value={organizationType}
					onChange={setOrganizationType}
					name="organization-type"
					options={organizationTypeOptions}
					required={true}
				/>
				<TextField
					label={t('Form.label.email')}
					htmlId={formName + 'email'}
					name="email"
					value={email}
					onChange={setEmail}
					autoComplete="email"
					required
					type="email"
					className="col-start-1"
				/>
				<TextField
					label={t('Form.label.phone')}
					htmlId={formName + 'phone'}
					name="phone"
					value={phone}
					onChange={setPhone}
					autoComplete="tel"
					type="tel"
					required
				/>
				{showProductOptions && (
					<div className="sm:col-span-2 sm:pr-1/3">
						<Select
							label={t('Form.label.product')}
							name="product"
							value={product}
							onChange={setProduct}
							options={productOptions}
							required={true}
						/>
					</div>
				)}
				<div className="sm:col-span-2 sm:pr-1/3">
					<Select
						label={t('Form.label.howhear')}
						name="howhear"
						value={howhear}
						onChange={setHowhear}
						options={howhearOptions}
					/>
				</div>
				<TextArea
					label={t('Form.label.message')}
					htmlId={formName + 'message'}
					name="message"
					value={message}
					onChange={setMessage}
					className="sm:col-span-2"
				/>
				<div className="pt-10 sm:col-span-2">
					<Checkbox
						name="privacypolicy"
						htmlId={formName + 'privacypolicy'}
						required
						label={terms}
					/>
				</div>
				{recaptchaEnabled && (
					<div className="pt-6">
						{recaptchaReady ? (
							<Recaptcha
								ref={recaptchaRef}
								sitekey={recaptchaSiteKey}
								size="compact"
								onErrored={recaptchaOnErrored}
								onExpired={recaptchaOnExpired}
								asyncScriptOnLoad={recaptchaAsyncScriptOnLoad}
							/>
						) : (
							<p>{t('Form.error.loadingRecaptcha')}</p>
						)}

					</div>
				)}
				{errorMessage && (
					<RichText className="prose text-error sm:col-span-2">
						{errorMessage}
					</RichText>
				)}
				<div className="pt-6 sm:col-span-2">
					<Button
						as="button"
						type="submit"
						className={cn(isPending && 'opacity-50')}
					>
						{t(`SectionsAccountRegistration.${variant}.submit`)}
					</Button>
				</div>
				{pendingMessage && isPending && (
					<RichText className="prose sm:col-span-2">{pendingMessage}</RichText>
				)}
			</div>
		</form>
	)
}

FormRegistration.defaultProps = {
	name: 'registration',
	redirects: {},
}

FormRegistration.propTypes = {
	name: PropTypes.string,
	variant: PropTypes.string,
	redirects: PropTypes.object,
}

export default FormRegistration
